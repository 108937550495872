
































































import Vue from 'vue';
import axios from 'axios';
// eslint-disable-next-line import/no-cycle

export default Vue.extend({
  data() {
    return {
      iniData: [
        { x: 'TITLE', y: [0, 0] }, { x: 'A', y: [0, 0] }, { x: 'B', y: [0, 0] }, { x: 'C', y: [0, 0] },
        { x: 'D', y: [0, 0] },
      ],
      series: [
        {
          data: [
            { x: 'Title', y: [0, 0] }, { x: 'A', y: [0, 0] }, { x: 'B', y: [0, 0] }, { x: 'C', y: [0, 0] },
            { x: 'D', y: [0, 0] },
          ],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'rangeBar',
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          type: 'datetime',
        },
      },
      code: '',
      answerGroupId: '',
      currentNum: 0,
      list: null as any,
      quizTitle: '',
      items: { details: [{ area: 'Total', Used_Time: 0 }] } as any,
      answerGroupData: null as any,
      answerList: null as any,
    };
  },
  created() {
    this.answerGroupId = this.$route.query.answerGroupId as string;
    axios
      .get(`https://eye-tracking-api.qsticker.net/v1/tracking/analysis?answerGroupId=${this.answerGroupId}`)
      .then((res) => {
        console.log(res.data);
        this.quizTitle = res.data.sourceQuizGroupName;
        this.answerGroupId = res.data.answerGroupId;

        this.list = res.data.list;

        const tempChartData = this.iniData;
        res.data.list[this.currentNum].sequence.forEach((element : any) => {
          tempChartData.push({ x: element[0], y: [element[1], element[2]] });
        });
        this.series = [{ data: tempChartData }];
        this.setAnswerGroup();
        this.handleUsageData();
      });
  },
  methods: {
    setAnswerGroup() {
      axios
        .get(`https://api.qsticker.net/service/v1/answer-group/${this.answerGroupId}`)
        .then((res) => {
          console.log(res.data);
          this.answerGroupData = res.data;
          const tempList: { score: any; userAnswer: any; correctAnswer: any; }[] = [];
          this.list.forEach((element: any) => {
            this.answerGroupData.answerList.forEach((agEle: any) => {
              if (agEle.uuid === element.answer_uuid) {
                let myAnswer = '';
                agEle.userAnswer.forEach((i : any) => {
                  myAnswer = `${myAnswer + i} `;
                });

                let correctAnswer = '';
                agEle.sourceQuiz.answerSet.forEach((i: any) => {
                  correctAnswer = `${correctAnswer + i} `;
                });
                tempList.push({ score: agEle.score, userAnswer: myAnswer, correctAnswer });
              }
            });
          });
          this.answerList = tempList;
        });
    },
    // this.series = [{
    //       data: newData
    //     }]
    handleUsageData() {
      const tempList = [];
      const listJson = JSON.parse(JSON.stringify(this.list[this.currentNum]));
      tempList.push({ 區域: '全部', 凝視停留時間: `${listJson.total_used_time} ms` });
      listJson.detail_used_time.forEach((element: any[]) => {
        tempList.push({ 區域: element[0], 凝視停留時間: `${element[1]} ms` });
      });
      this.items = tempList;
      this.$set(this.items, 'details', tempList);
    },
    refreshChart() {
      this.series = [{ data: [] }];
      setTimeout(() => {
        console.log();
      }, 100);
      const tempChartData = [
        { x: 'TITLE', y: [0, 0] }, { x: 'A', y: [0, 0] }, { x: 'B', y: [0, 0] }, { x: 'C', y: [0, 0] },
        { x: 'D', y: [0, 0] },
      ];
      (JSON.parse(JSON.stringify(this.list[this.currentNum]))).sequence.forEach((element : any) => {
        tempChartData.push({ x: element[0], y: [element[1], element[2]] });
      });
      this.series = [{ data: tempChartData }];
    },
    preBtnClicked() {
      if (this.currentNum > 0) {
        this.currentNum -= 1;
        this.refreshChart();
        this.handleUsageData();
      }
    },
    nextBtnClicked() {
      if (this.currentNum < this.list.length - 1) {
        this.currentNum += 1;
        this.refreshChart();
        this.handleUsageData();
      }
    },
  },
});
