import Vue from 'vue';
import VueRouter, { RawLocation, Route, RouteConfig } from 'vue-router';
import Chart from '@/views/Chart.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Chart.vue'),
  },
  {
    path: '/chart',
    name: 'Chart',
    component: Chart,
  },
];

const router = new VueRouter({
  routes,
});

/**
 * Ignore NavigationDuplicated error
 *
 * @see https://stackoverflow.com/a/67102038/5731332
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalPush.call(this, location, () => {
      // on complete

      resolve(this.currentRoute);
    }, (error) => {
      // on abort

      // only ignore NavigationDuplicated error
      if (error.name === 'NavigationDuplicated') {
        resolve(this.currentRoute);
      } else {
        reject(error);
      }
    });
  });
};

export default router;
